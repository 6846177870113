<template>
  <img ref="el" :data-attr="$attrs['data-attr']" :alt="alt" :width="width" :height="height" :src="srcImage" />
</template>

<script setup lang="ts">
defineComponent({
  name: 'LImage',
});
const props = defineProps({
  src: {
    type: String,
    default: '',
  },
  alt: {
    type: String,
    default: '',
  },
  width: {
    type: String,
    default: '',
  },
  height: {
    type: String,
    default: '',
  },
});

const observer = ref<IntersectionObserver | null>(null);
const intersected = ref(false);
const el = ref();

const srcImage = computed(() => (intersected?.value ? props.src : ''));

onMounted(() => {
  observer.value = new IntersectionObserver(entries => {
    const image = entries[0];
    if (image.isIntersecting) {
      intersected.value = true;
      observer.value?.disconnect();
    }
  });
  observer.value.observe(el.value);
});

onBeforeUnmount(() => {
  observer.value?.disconnect();
});
</script>
