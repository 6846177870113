<template>
  <DelayHydration>
    <div v-if="content && content.items && content.items.length">
      <CarouselWrapper
        :id="id"
        :title="content.title"
        :subtitle="content.subtitle"
        :type="fragmentType"
        :products="content.items"
      />
    </div>
  </DelayHydration>
</template>
<script setup lang="ts">
import type { CarouselContent } from '@robustastudio/e-commerce/common';
import type { PropType } from 'vue';
function generateRandomPrefix() {
  const letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  let className = '';
  for (let i = 0; i < 6; i++) {
    className += letters.charAt(Math.floor(Math.random() * letters.length));
  }
  return className;
}
const props = defineProps({
  content: {
    type: Object as PropType<CarouselContent>,
    default: () => null,
  },
  componentStyle: {
    type: String,
    default: ProductRenderStyle.DEFAULT,
  },
  type: {
    type: String,
    default: 'default',
  },
  component: {
    type: Object,
    default: () => null,
  },
});
const fragmentType = computed(() => {
  return props.componentStyle === ProductRenderStyle.INVERTED ? 'inverted' : 'default';
});

const id = computed(() => {
  return props.content?.id?.toString() + generateRandomPrefix() || '-1';
});
</script>
