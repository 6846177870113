<template>
  <div class="OfferModelArt1Container bg-primary-2-10 mt-30 md:mt-46 relative px-5 lg:px-0 py-15 lg:py-0">
    <div class="container mx-auto h-full">
      <div
        v-if="offer"
        class="OfferModelArt1 flex flex-col lg:flex-row items-center gap-y-12 md:gap-y-0 md:gap-x-12 relative h-full"
      >
        <div class="flex flex-col items-start justify-center order-2 md:order-none">
          <h1 v-if="offer?.title" class="text-xl-1 md:text-4xl lg:w-8/12">
            {{ offer?.title }} <span class="font-bold">{{ offer?.subtitle }} </span>
          </h1>

          <p v-if="offer?.description" class="mt-7 text-primary-1-60 lg:w-8/12">
            {{ offer?.description }}
          </p>

          <AppButton
            v-if="offer?.cta"
            as="a"
            :href="offer?.href"
            class="whitespace-nowrap mt-10 w-2/3 lg:w-1/3 uppercase"
          >
            {{ offer?.cta }}
          </AppButton>
        </div>
        <div class="order-1 md:order-none relative w-full md:w-auto">
          <div class="OfferImage ml-auto md:ml-0">
            <picture v-if="offer.image">
              <AppSource :srcset="offer.image" format="avif" media="(min-width: 768px)" width="494" height="650" />
              <AppSource :srcset="offer.image" format="webp" media="(min-width: 768px)" width="494" height="650" />
              <AppSource :srcset="offer.image" format="jpeg" media="(min-width: 768px)" width="494" height="650" />

              <AppSource
                v-if="offer.mobileImage"
                :srcset="offer.mobileImage"
                media="(max-width: 767px)"
                width="304"
                height="400"
                format="avif"
              />
              <AppSource
                v-if="offer.mobileImage"
                :srcset="offer.mobileImage"
                media="(max-width: 767px)"
                width="304"
                height="400"
                format="webp"
              />
              <AppSource
                v-if="offer.mobileImage"
                :srcset="offer.mobileImage"
                media="(max-width: 767px)"
                width="304"
                height="400"
                format="jpeg"
              />

              <AppImage
                :src="offer.image"
                :alt="offer.title || ''"
                class="w-full object-cover order border-transparent rounded-2md"
                loading="lazy"
              />
            </picture>
          </div>
          <div v-if="offer.secondaryImage" class="OfferSecondaryImage">
            <picture>
              <AppSource
                :srcset="offer.secondaryImage"
                format="avif"
                media="(min-width: 768px)"
                width="346"
                height="500"
              />
              <AppSource
                :srcset="offer.secondaryImage"
                format="webp"
                media="(min-width: 768px)"
                width="346"
                height="500"
              />
              <AppSource
                :srcset="offer.secondaryImage"
                format="jpeg"
                media="(min-width: 768px)"
                width="346"
                height="500"
              />

              <AppSource
                :srcset="offer.secondaryImage"
                format="avif"
                media="(max-width: 767px)"
                width="207"
                height="300"
              />
              <AppSource
                :srcset="offer.secondaryImage"
                format="webp"
                media="(max-width: 767px)"
                width="207"
                height="300"
              />
              <AppSource
                :srcset="offer.secondaryImage"
                format="jpeg"
                media="(max-width: 767px)"
                width="207"
                height="300"
              />
              <AppImage
                :src="offer.secondaryImage"
                :alt="offer.secondaryImageDescription || ''"
                class="w-full object-cover border border-transparent rounded-3xs rounded-tr-none"
                loading="lazy"
              />
            </picture>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { OfferType } from '~/composables/offers';

const props = defineProps({
  content: {
    type: Array as PropType<OfferType>,
    default: () => [],
  },
});

const offer = computed(() => props.content?.at(0));
</script>

<style lang="postcss" scoped>
.OfferModelArt1Container {
  margin-bottom: 60px;
  @screen md {
    margin-bottom: 221px;
    height: 467px;
  }
}
.OfferImage {
  aspect-ratio: 0.76;
  width: 240px;
  height: 316px;
  margin-top: -110px;

  @screen md {
    width: 448px;
    height: 589px;
    flex-shrink: 0;
    margin-top: 0;
    border-radius: 60px;
  }
}
.OfferSecondaryImage {
  @apply absolute left-0;
  aspect-ratio: 0.69;
  width: 156px;
  height: 225px;
  bottom: -30px;

  @screen md {
    bottom: -55px;
    left: auto;
    right: 369px;
    width: 285px;
    height: 411px;
    flex-shrink: 0;
    border-radius: 30px;
    border-top-right-radius: 0;
  }
}
</style>
