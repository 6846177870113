<template>
  <section class="mt-10 lg:mt-17 px-6 lg:px-0 bg-primary-2-07">
    <div class="container mx-auto py-15">
      <h2 class="text-primary-1-100 md:text-center text-xl-1 md:text-4xl">
        {{ $t('title') }}
      </h2>

      <DelayHydration>
        <div class="mt-12 md:mt-10 grid grid-cols-1 md:grid-cols-4 gap-x-7 gap-y-13 md:gap-15">
          <div v-for="(block, index) in blockContent" :key="index" class="flex flex-col gap-y-5">
            <img :src="mediaUrl + block.image" :alt="block.title" class="w-6 h-6 object-contain" />
            <h3 class="text-primary-1-100 font-bold">{{ block.title }}</h3>
            <p class="text-primary-1-60 mb-2">{{ block.description }}</p>
          </div>
        </div>
      </DelayHydration>
    </div>
  </section>
</template>

<script setup lang="ts">
const {
  public: { mediaUrl },
} = useRuntimeConfig();
const { data: blockContent } = await useLazyAsyncData(async () => {
  const blockContent = await useHomeBlockSection();
  return blockContent;
});
const { t: $t } = useI18n({
  useScope: 'local',
});
</script>

<style scoped>
img {
  max-width: 44px;
}
</style>

<i18n>
{
    "en": {
      "title": "A Unique Experience",
      "learnMore": "Learn More"
    },
    "ar": {
      "title": "تجربة فريدة",
      "learnMore": "اعرف أكثر"
    }
}
</i18n>
