<template>
  <div class="pb-8 pt-7">
    <Spinner v-if="pending" class="w-6 h-6 mx-auto" />
    <HomeComponentFactory v-else :items="data?.homeContent" />
  </div>
</template>

<script setup lang="ts">
useAppSeoMeta();

const { pending, data } = useAsyncData(
  async () => await useHomePage(sectionTypeMap, styleFn, homeContentMapper, fetchHomeQuery as any),
);
</script>

<style lang="postcss" scoped>
.Offers {
  :deep(.OfferCard) {
    &:first-child {
      @apply col-span-2;

      @screen lg {
        @apply row-span-2;
      }
    }
  }
}

.Categories {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: 162px;
  column-gap: 10px;
  row-gap: 10px;

  @screen lg {
    grid-template-columns: repeat(4, 1fr);
    grid-auto-rows: 285px;
    column-gap: 20px;
    row-gap: 20px;
  }

  > *:last-child:nth-child(even) {
    @apply col-span-2;
  }
}

.SecondaryOffers {
  &__Grid {
    @apply px-5 lg:px-0 grid grid-cols-1 lg:grid-cols-2 gap-x-5 gap-y-5 pt-16;
    grid-auto-rows: 180px;

    @screen lg {
      grid-auto-rows: 333px;
    }

    :deep(.OfferCard) {
      &:first-child {
        @screen lg {
          @apply row-span-2;
        }
      }
    }
  }

  &__bg {
    @apply bg-primary-100;
    height: 500px;
  }
}

.background-layer {
  height: 850px;
  margin-top: -500px;
  margin-bottom: -375px;

  @screen md {
    height: 546px;
    margin-top: -521px;
    margin-bottom: 0;
  }
}
</style>

<i18n>
{
  "en": {
    "scrollDown": "Scroll Down",
    "shopByCategory": "Shop By Category",
    "sale": "Sale",
    "saleDesc": "Don’t miss our monthly deals on selective products",
    "latest": "Latest Products",
    "bestSellers": "Best Sellers",
    "topBrands": "Top Brands",
    "seo": {
      "title": "Home",
      "description": "Home page description"
    }
  },
  "ar": {
    "scrollDown": "",
    "shopByCategory": "تسوق بالقسم",
    "sale": "تخفيضات",
    "saleDesc": "لا يفوتك عروضنا الشهرية",
    "latest": "آخر المنتجات",
    "bestSellers": "الأكثر مبيعا",
    "topBrands": "اشهر الماركات",
    "seo": {
      "title": "الرئيسية",
      "description": "وصف الرئيسية"
    }
  }
}
</i18n>
