<template>
  <section class="mt-10 lg:mt-20 container mx-auto">
    <div dir="left" class="text-gray-500 px-5 md:px-0 text-sm uppercase justify-center mb-2">
      {{ t('ourPartners') }}
    </div>
    <h2 class="text-primary-1-100 px-5 md:px-0 uppercase text-left text-2xl md:text-2.5xl">
      {{ t('topBrands') }}
    </h2>

    <BrandSlider
      v-if="brands?.length"
      v-slot="{ item }"
      class="mt-6 lg:mt-8 pl-6 lg:pl-0"
      :items="brands"
      :auto-play="5000"
    >
      <AppLink :to="`/brands/${item.url_key}`" class="flex items-center justify-center max-h-12 max-w-12 mx-auto">
        <LImage
          class="max-h-12 object-contain"
          :src="item.image_url || '/logo.svg'"
          :alt="item.image_alt_value || item.name"
          loading="lazy"
          width="158"
        />
      </AppLink>
    </BrandSlider>
  </section>
</template>
<script setup lang="ts">
import type { Brand } from '@robustastudio/e-commerce/common';

const { data: brands } = useLazyAsyncData(async () => {
  const { brands } = await useFeaturedBrands();
  return brands as Brand[];
});
const { t } = useI18n({
  useScope: 'local',
});
</script>
<style lang="postcss" scoped>
.brand {
  flex-basis: calc(100% / 7);

  & > a img {
    max-height: 90px;
    max-width: 70px;
    @screen lg {
      max-height: 78px;
      max-width: 100px;
    }
  }

  @media (max-width: 767px) {
    flex-basis: 25%;
  }
}
</style>
<i18n>
{
    "en": {
        "topBrands": "Featured Brands",
        "ourPartners": "Our Partners in Success"
    },
    "ar": {
        "topBrands": "الماركات",
        "ourPartners": "شركاؤنا فى النجاح"
    }
}
</i18n>
