<template>
  <ClientOnly>
    <div v-if="!pending && user">
      <CarouselWrapper
        :id="String(content?.id) || ''"
        :title="content?.title || ''"
        :subtitle="content?.subtitle || ''"
        type="default"
        :products="content?.items || []"
      />
    </div>
  </ClientOnly>
</template>
<script setup lang="ts">
const { user } = useAuth();
defineProps({
  componentStyle: {
    type: String as PropType<string>,
    default: ProductRenderStyle.DEFAULT,
  },
});

const { data: content, pending } = useAsyncData(
  async () => {
    const response = await useRecentlyViewed();

    return response;
  },
  {
    server: false,
  },
);
</script>
