<template>
  <div>
    <template v-for="(item, index) in homeItems" :key="index">
      <component :is="item.component" v-bind="item" />
    </template>
  </div>
</template>
<script setup lang="ts">
import type { HomeComponent, HomeComponentContent } from '@robustastudio/e-commerce/common';
import type { PropType } from 'vue';

const componentMapper: Record<string, ReturnType<typeof resolveComponent>> = {
  [HomeComponentType.PRODUCTS_CAROUSEL]: resolveComponent('HomeProductCarouselFragment'),
  [HomeComponentType.OFFERS]: resolveComponent('HomeOfferComponentFactory'),
  [HomeComponentType.FEATURED_CATEGORIES]: resolveComponent('HomeCategoryFragment'),
  [HomeComponentType.FEATURED_BRANDS]: resolveComponent('HomeFeaturedBrandsFragment'),
  [HomeComponentType.ABOUT_BLOCKS]: resolveComponent('HomeInformativeBannerFragment'),
  [HomeComponentType.BEST_SELLERS_CAROUSEL]: resolveComponent('HomeBestSellersFragment'),
  [HomeComponentType.RECENTLY_VIEWED_CAROUSEL]: resolveComponent('HomeRecentlyViewedFragment'),
};

const props = defineProps({
  items: {
    type: Array as PropType<HomeComponent<HomeComponentContent>[]>,
    default: () => [],
  },
});

const homeItems = computed(() => {
  return props.items?.map(item => {
    return { ...item, component: componentMapper[item.type] };
  });
});
</script>
