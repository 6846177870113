<template>
  <div
    class="OfferSlider overflow-hidden lg:overflow-visible relative"
    :class="{
      'OfferSlider--left-layout': orientation === 'left',
      'OfferSlider--right-layout': orientation === 'right',
    }"
  >
    <Swiper
      :dir="dir"
      class="h-full"
      :modules="[SwiperNavigation, SwiperAutoplay]"
      :centered-slides="true"
      :center-insufficient-slides="true"
      :slides-per-view="1.3"
      :space-between="23"
      :breakpoints="{
        '768': {
          slidesPerView: props.items.length < 4 ? props.items.length : 4,
          spaceBetween: 25,
        },
        '1400': {
          slidesPerView: props.items.length < 5 ? props.items.length : 4.4,
          spaceBetween: 25,
        },
      }"
      :autoplay="autoPlay ? { delay: autoPlay } : false"
      :navigation="{
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      }"
    >
      <SwiperSlide v-for="(item, idx) in items" :key="idx" class="flex items-center mt-auto">
        <slot :item="{ ...item, idx }" />
      </SwiperSlide>
      <SwiperControls :current-index="currentIndex" @change="index => emit('change', index)" />
    </Swiper>

    <!-- If we need navigation buttons -->
    <div class="flex items-center mb-8 mx-auto lg:mx-0 swiper-nav">
      <button class="flex items-center gap-x-4" aria-label="previous slide" @click="prevSlide">
        <svg-icon-arrow-left class="w-3 h-5" />
        <span>0{{ currentIndex + 1 }} / </span>
      </button>
      <button class="flex items-center gap-x-4" aria-label="next slide" @click="nextSlide">
        <span>0{{ items.length }} </span>
        <svg-icon-arrow-right class="w-3 h-5" />
      </button>
    </div>
  </div>
</template>
<script setup lang="ts">
import type { OfferCardFragment } from '~/graphql/fragments';
import type { PropType } from 'vue';
import type { Unpacked } from '~/types/utils';
import type { OffersContent } from '@robustastudio/e-commerce/common';

type Offer = Array<Unpacked<OffersContent> & Partial<OfferCardFragment>>;

const props = defineProps({
  items: {
    type: Array as PropType<Offer>,
    required: true,
  },
  type: {
    type: String,
    default: 'primary',
  },
  autoPlay: {
    type: Number,
    default: 0,
  },
  desktopSlides: {
    type: [Number, String],
    default: 2,
  },
  desktopOptions: {
    type: Object as PropType<any>,
    default: () => ({}),
  },
  mobileOptions: { type: Object, default: () => ({}) },
  arrows: {
    type: Boolean,
    default: false,
  },
  inverted: {
    type: Boolean,
    default: false,
  },
  index: {
    type: Number,
    default: 1,
  },
  orientation: {
    type: String,
    default: 'normal',
  },
  dir: {
    type: String,
    default: 'ltr',
  },
});

const emit = defineEmits(['change']);

const currentIndex = ref(0);

function nextSlide() {
  if (currentIndex.value + 1 < props.items.length) {
    currentIndex.value = currentIndex.value + 1;
  } else {
    currentIndex.value = 0;
  }
}

function prevSlide() {
  if (currentIndex.value - 1 >= 0) {
    currentIndex.value = currentIndex.value - 1;
  } else {
    currentIndex.value = 0;
  }
}
</script>

<style lang="postcss" scoped>
.-scale-x-100 {
  transform: scaleX(-1);
}

.swiper {
  padding-bottom: 160px;
}

.OfferSlider {
  @apply relative;
  :deep(.OfferCard__img) {
    @apply rounded-3xs rounded-bl-none;
  }

  .swiper-nav {
    @apply hidden;
    @screen lg {
      @apply flex absolute;
      right: 138px;
      top: 135px;
      gap: 4px;
      z-index: 4;
    }
  }

  :deep(.swiper-slide-prev) {
    .OfferCard {
      margin-left: auto;
      &__img {
        @apply rounded-3xs rounded-br-none;
      }
    }
  }

  :deep(.swiper-slide-active) {
    @apply relative;
    min-width: 322px;

    @screen lg {
      min-width: 442px;
    }
  }

  &--right-layout {
    .swiper-nav {
      left: 138px;
      right: auto;
    }

    :deep(.OfferCard__content) {
      right: 324px;
      left: auto;
    }
  }
}
</style>
