<template>
  <div
    class="relative pb-12 md:pb-0 px-5 lg:px-0"
    :class="{
      'bg-primary-3-40 py-10 md:py-17.5': type === 'dimmed',
    }"
  >
    <div class="relative container mx-auto">
      <DelayHydration>
        <Swiper
          :modules="[SwiperNavigation, SwiperAutoplay]"
          :slides-per-view="1"
          :centered-slides="false"
          :center-insufficient-slides="false"
          :loop="false"
          :slides-offset-before="0"
          :slides-offset-after="0"
          :space-between="15"
          :breakpoints="{
            '400': {
              slidesPerView: 1,
              spaceBetween: 30,
              centeredSlides: true,
            },
            '768': {
              slidesPerView: 1,
              spaceBetween: 20,
              centerInsufficientSlides: false,
              centeredSlides: false,
            },
          }"
          :navigation="{
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          }"
          class="h-full swiper-container"
        >
          <SwiperSlide
            v-for="(item, idx) in offers"
            :key="idx"
            :class="{
              'active-slide': currentIndex + 1 === idx,
            }"
          >
            <div class="slider-container grid lg:flex w-full md:pt-15 gap-y-16 md:gap-y-0 md:gap-x-14">
              <div class="OfferImage">
                <picture v-if="item">
                  <AppSource :srcset="item.image" format="avif" media="(min-width: 768px)" width="488" height="650" />
                  <AppSource :srcset="item.image" format="webp" media="(min-width: 768px)" width="488" height="650" />
                  <AppSource :srcset="item.image" format="jpeg" media="(min-width: 768px)" width="488" height="650" />

                  <AppSource
                    v-if="item.mobileImage"
                    :srcset="item.mobileImage"
                    media="(max-width: 767px)"
                    width="333"
                    height="450"
                    format="avif"
                  />
                  <AppSource
                    v-if="item.mobileImage"
                    :srcset="item.mobileImage"
                    media="(max-width: 767px)"
                    width="333"
                    height="450"
                    format="webp"
                  />
                  <AppSource
                    v-if="item.mobileImage"
                    :srcset="item.mobileImage"
                    media="(max-width: 767px)"
                    width="333"
                    height="450"
                    format="jpeg"
                  />

                  <AppImage
                    :src="item.image"
                    :alt="item.title || ''"
                    class="w-full object-cover border border-transparent rounded-t-3xs rounded-bl-3xs"
                    :loading="idx === 0 ? 'eager' : 'lazy'"
                    :fetchpriority="idx === 0 ? 'high' : 'auto'"
                  />
                </picture>
              </div>

              <div v-if="item" class="text-content flex flex-col items-start justify-center">
                <header class="mb-7 lg:mb-0">
                  <h1 v-if="item.title" class="OfferTitle text-4xl lg:text-5xl">
                    {{ item.title }}
                  </h1>
                  <p v-if="item.subtitle" class="font-bold text-4xl lg:text-5xl">
                    {{ item.subtitle }}
                  </p>
                </header>

                <p v-if="item.description" class="text-primary-1-60 md:w-2/3">
                  {{ item.description }}
                </p>

                <AppButton
                  v-if="item.cta"
                  as="a"
                  :href="item.href"
                  class="whitespace-nowrap mt-10 md:mb-15 w-4/6 text-center uppercase"
                >
                  {{ item.cta }}
                </AppButton>
                <div
                  class="hidden md:block w-full h-1 bg-primary-2-20 border border-transparent rounded-sm relative mt-15"
                >
                  <span
                    class="slide-indicator h-full border border-primary-2-100 rounded-sm bg-primary-2-100 absolute left-0"
                  ></span>
                </div>
              </div>

              <div v-if="item" class="secondary-image-container flex flex-col">
                <div class="flex items-center mb-8 mx-auto lg:mx-0">
                  <button
                    class="flex items-center gap-x-4"
                    :disabled="idx === 0"
                    aria-label="previous slide"
                    @click="prevSlide"
                  >
                    <svg-icon-arrow-left class="w-3 h-5" />
                    <span>0{{ idx + 1 }} / </span>
                  </button>
                  <button
                    class="flex items-center gap-x-4"
                    aria-label="next slide"
                    :disabled="idx >= offers.length"
                    @click="nextSlide"
                  >
                    <span>0{{ offers.length }} </span>
                    <svg-icon-arrow-right class="w-3 h-5" />
                  </button>
                </div>

                <div v-if="item && item.secondaryImage" class="flex mt-auto">
                  <div class="OfferSecondaryImage">
                    <picture>
                      <AppSource
                        :srcset="item.secondaryImage"
                        format="avif"
                        media="(min-width: 768px)"
                        width="320"
                        height="400"
                      />

                      <AppSource
                        :srcset="item.secondaryImage"
                        format="webp"
                        media="(min-width: 768px)"
                        width="320"
                        height="400"
                      />

                      <AppSource
                        :srcset="item.secondaryImage"
                        format="jpeg"
                        media="(min-width: 768px)"
                        width="320"
                        height="400"
                      />

                      <AppSource
                        :srcset="item.secondaryImage"
                        format="avif"
                        media="(max-width: 767px)"
                        width="240"
                        height="300"
                      />

                      <AppSource
                        :srcset="item.secondaryImage"
                        format="webp"
                        media="(max-width: 767px)"
                        width="240"
                        height="300"
                      />

                      <AppSource
                        :srcset="item.secondaryImage"
                        format="jpeg"
                        media="(max-width: 767px)"
                        width="240"
                        height="300"
                      />

                      <AppImage
                        :src="item.secondaryImage"
                        :alt="item.title || ''"
                        class="w-full object-cover border border-transparent rounded-tl-3xs rounded-r-3xs"
                        :loading="idx === 0 ? 'eager' : 'lazy'"
                        :fetchpriority="idx === 0 ? 'high' : 'auto'"
                      />
                    </picture>
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperControls :current-index="currentIndex" @change="e => (currentIndex = e)" />
        </Swiper>
      </DelayHydration>
    </div>
  </div>
</template>
<script setup lang="ts">
import type { PropType } from 'vue';
import type { OfferType } from '~/composables/offers';

const props = defineProps({
  offers: {
    type: Array as PropType<OfferType>,
    default: () => [],
  },
  type: {
    type: String as PropType<'default' | 'dimmed'>,
    default: 'default',
    validator: (value: string) => ['default', 'dimmed'].includes(value),
  },
});

const currentIndex = ref(0);
const offersLength = computed(() => props.offers.length);
const currentSlide = computed(() => currentIndex.value + 1);

function nextSlide() {
  currentIndex.value = getValidValue(currentIndex.value + 1);
}

function prevSlide() {
  currentIndex.value = getValidValue(currentIndex.value - 1);
}

function getValidValue(num: number) {
  return clamp(0, props.offers.length - 1, num);
}
</script>
<style lang="postcss" scoped>
.featuredOffer {
  & > h1 {
    height: 95px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  & > p {
    height: 22px;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}

.slider-container {
  grid-template-areas: 'a b' 'c c';
}

.text-content {
  grid-area: c;
}
.secondary-image-container {
  grid-area: b;
  margin-bottom: -24px;
  @screen md {
    margin-bottom: 0;
  }
}
.slide-indicator {
  width: calc((v-bind(currentSlide)) / v-bind(offersLength) * 100%);
  height: 4px; /** height:  = parents height* 2 to cover border; */
  bottom: -1px; /** -1 to cover parent border */
  left: -1px;
}
.title {
  height: 50px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;

  @screen md {
    -webkit-line-clamp: 1;
    height: 22px;
  }
}

.slide-grid {
  display: grid;
  grid-template-columns: 1fr 334px 1fr;
  grid-gap: 15px;

  @screen md {
    grid-template-columns: 1fr;
  }
}

.swiper-slide {
  @apply my-auto;
  width: 276px;

  & div div a img {
    width: 100%;
    height: 444px;
    aspect-ratio: 0.75;

    @screen md {
      width: 276px;
      height: auto;
      aspect-ratio: 0.75;
    }
  }

  &.active-slide {
    width: 488px;

    & div div a img {
      width: 100%;
      aspect-ratio: 0.76;
    }
  }
}

.OfferImage {
  aspect-ratio: 0.74;
  height: 310px;
  width: 232px;
  grid-area: a;

  @screen md {
    aspect-ratio: 0.75;
    width: 426px;
    height: 568px;
    flex-shrink: 0;
    margin-inline-start: 0;
  }
}

.OfferSecondaryImage {
  width: calc(100% + 40px);
  margin-left: -40px;
  @screen md {
    display: inline-flex;
    aspect-ratio: 0.8;
    width: 252px;
    height: 314px;
    flex-shrink: 0;
    margin-left: 0;
  }
}

.OfferTitle {
  @apply flex items-center gap-x-2.5 md:gap-x-5;
}
</style>
<i18n>
  {
    "en": {
      "fashionDeals": "fashion deals",
      "next": "Next",
      "previous": "Previous",
      "viewOffer": "View offer"
    },
    "ar": {
      "fashionDeals": "عروض الموضة",
      "next": "التالي",
      "previous": "السابق",
      "viewOffer": "عرض العرض"
    }
  }
</i18n>
